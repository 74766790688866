import stylesParams from '../../../stylesParams';
import settingsParams from '../../../settingsParams';

export const styleParamsToReset = {
  backgroundColor: stylesParams.backgroundColor,
  productTitleColor: stylesParams.productTitleColor,
  productTitleFont: stylesParams.productTitleFont,
  productPriceColor: stylesParams.productPriceColor,
  productPriceFont: stylesParams.productPriceFont,
  productDescriptionColor: stylesParams.productDescriptionColor,
  productDescriptionFont: stylesParams.productDescriptionFont,
  imageBorderColor: stylesParams.imageBorderColor,
  imageBorderWidth: stylesParams.imageBorderWidth,
  imageCornerRadius: stylesParams.imageCornerRadius,
  imageRatio: stylesParams.imageRatio,
  inputFieldTitlesColor: stylesParams.inputFieldTitlesColor,
  inputFieldTitlesFont: stylesParams.inputFieldTitlesFont,
  ProductVariantsFillColorOpacity: stylesParams.ProductVariantsFillColorOpacity,
  ProductVariantsSelectedBoxColor: stylesParams.ProductVariantsSelectedBoxColor,
  ProductVariantsBorderColor: stylesParams.ProductVariantsBorderColor,
  ProductVariantsBorderWidth: stylesParams.ProductVariantsBorderWidth,
  ProductVariantsTextColor: stylesParams.ProductVariantsTextColor,
  ProductVariantsTextFont: stylesParams.ProductVariantsTextFont,
  inputFieldBackgroundColor: stylesParams.inputFieldBackgroundColor,
  inputFieldColor: stylesParams.inputFieldColor,
  inputFieldFont: stylesParams.inputFieldFont,
  AddToCartButtonFillColorOpacity: stylesParams.AddToCartButtonFillColorOpacity,
  AddToCartButtonBorderColor: stylesParams.AddToCartButtonBorderColor,
  AddToCartButtonBorderWidth: stylesParams.AddToCartButtonBorderWidth,
  AddToCartButtonCornerRadius: stylesParams.AddToCartButtonCornerRadius,
  AddToCartButtonTextColor: stylesParams.AddToCartButtonTextColor,
  AddToCartButtonTextFont: stylesParams.AddToCartButtonTextFont,
  BuyNowButtonFillColorOpacity: stylesParams.BuyNowButtonFillColorOpacity,
  BuyNowButtonBorderColor: stylesParams.BuyNowButtonBorderColor,
  BuyNowButtonBorderWidth: stylesParams.BuyNowButtonBorderWidth,
  BuyNowButtonCornerRadius: stylesParams.BuyNowButtonCornerRadius,
  BuyNowButtonTextColor: stylesParams.BuyNowButtonTextColor,
  BuyNowButtonTextFont: stylesParams.BuyNowButtonTextFont,
};

export const settingsParamsToReset = {
  productTitleAlignment: settingsParams.productTitleAlignment,
};

export enum RatioValue {
  RATIO_16X9 = '16:9',
  RATIO_1X1 = '1:1',
  RATIO_2X3 = '2:3',
  RATIO_3X2 = '3:2',
  RATIO_3X4 = '3:4',
  RATIO_4X3 = '4:3',
  RATIO_9X16 = '9:16',
}

export const ratioValueMap: Record<RatioValue, number> = {
  [RatioValue.RATIO_16X9]: 16 / 9,
  [RatioValue.RATIO_1X1]: 1,
  [RatioValue.RATIO_2X3]: 2 / 3,
  [RatioValue.RATIO_3X2]: 3 / 2,
  [RatioValue.RATIO_3X4]: 3 / 4,
  [RatioValue.RATIO_4X3]: 4 / 3,
  [RatioValue.RATIO_9X16]: 9 / 16,
};
