import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import _ from 'lodash';
import validator from 'validator';
import s from './Quantity.scss';
import { classes } from './Quantity.st.css';
import settingsParams from '../../../settingsParams';
import { useControllerProps } from '../../ControllerContext';
import { useState } from '../../stores';
import { Counter, CounterSize } from 'wix-ui-tpa';

export enum QuantityDataHook {
  quantitySelector = 'QuantityDataHook.quantitySelector',
}

const Quantity = () => {
  const {
    state: { checkout },
    dispatch,
  } = useState();
  const settings = useSettings();
  const {
    purchase_options_validation,
    show_errors,
    setPurchaseOptionsValidation,
  } = useControllerProps().giftCardCheckoutStore;

  return (
    <fieldset data-hook={QuantityDataHook.quantitySelector}>
      <legend className={s.label}>
        {settings.get(settingsParams.quantityLabel)}
      </legend>
      <Counter
        data-hook="rise-gift-card-quantity-counter"
        className={classes.counter}
        onChange={(value) => {
          dispatch({
            type: 'setPurchaseOptions',
            payload: {
              quantity: Number(value),
            },
          });
          setPurchaseOptionsValidation(
            'quantity',
            validator.isInt(value, { min: 1, max: 10 }),
          );
        }}
        min={1}
        max={10}
        errorMessage={settings.get(settingsParams.quantityErrorMessage)}
        error={show_errors && !_.get(purchase_options_validation, 'quantity')}
        inputAriaLabel={settings.get(settingsParams.srQuantityTotalQuantity)}
        size={CounterSize.medium}
        value={checkout.purchaseOptions.quantity}
      />
    </fieldset>
  );
};

export default Quantity;
