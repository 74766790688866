import React from 'react';
import moment from 'moment';
import { useSettings } from '@wix/tpa-settings/react';
import validator from 'validator';
import cx from 'classnames';
import _ from 'lodash';
import s from './ProductC2cForm.scss';
import { classes } from './ProductC2cForm.st.css';
import settingsParams from '../../../settingsParams';
import { useControllerProps } from '../../ControllerContext';
import { useState } from '../../stores';
import {
  TextField,
  TextArea,
  DatePickerInput,
  DatePickerInputProps,
} from 'wix-ui-tpa';

const MAX_MESSAGE_LENGTH = 500;

export const emailFieldRef = React.createRef<TextField>();

const ProductC2cForm = () => {
  const {
    state: { checkout },
    dispatch,
  } = useState();
  const settings = useSettings();
  const { locale, shouldUseNewGiftCardServices } =
    useControllerProps().giftCardStore;
  const {
    purchase_options_validation,
    show_errors,
    setPurchaseOptionsValidation,
  } = useControllerProps().giftCardCheckoutStore;

  const renderDatePicker = () => (
    <div className={s.textFieldContainer}>
      <div className={s.label}>
        <label>{settings.get(settingsParams.deliveryDateLabel)}</label>
      </div>
      <DatePickerInput
        data-hook="rise-gift-card-date-picker-input"
        excludePastDates={true}
        locale={locale as DatePickerInputProps['locale']}
        className={cx(s.datePickerInput, classes.datePickerInput)}
        placeholderText={settings.get(settingsParams.deliveryDateDefaultText)}
        value={checkout.purchaseOptions.deliverAt}
        dateInputAriaLabel={settings.get(settingsParams.deliveryDateLabel)}
        onChange={(value) => {
          if (!value || moment().isSame(value, 'day')) {
            return dispatch({
              type: 'setPurchaseOptions',
              payload: {
                deliverAt: undefined,
              },
            });
          }
          const fromatedDate = moment(value)
            .hour(moment().hour())
            .minutes(moment().minutes())
            .utc()
            .toDate();
          dispatch({
            type: 'setPurchaseOptions',
            payload: {
              deliverAt: fromatedDate,
            },
          });
        }}
      />
    </div>
  );

  return (
    <>
      <div className={s.textFieldContainer}>
        <TextField
          ref={emailFieldRef}
          required={true}
          type="email"
          inputMode="email"
          data-hook="rise-gift-card-recipient-email-field"
          maxLength={100}
          className={classes.textField}
          value={checkout.purchaseOptions.recipientEmail}
          label={`${settings.get(settingsParams.recipientEmailLabel)}`}
          errorMessage={settings.get(settingsParams.recipientEmailErrorMessage)}
          error={
            show_errors && !_.get(purchase_options_validation, 'recipientEmail')
          }
          autoComplete="email"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            dispatch({
              type: 'setPurchaseOptions',
              payload: {
                recipientEmail: value,
              },
            });
            setPurchaseOptionsValidation(
              'recipientEmail',
              validator.isEmail(value),
            );
          }}
        />
      </div>
      <div className={s.textFieldContainer}>
        <TextField
          data-hook="rise-gift-card-recipient-name-field"
          maxLength={50}
          className={classes.textField}
          value={checkout.purchaseOptions.recipientName}
          label={settings.get(settingsParams.recipientNameLabel)}
          autoComplete="name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: 'setPurchaseOptions',
              payload: {
                recipientName: e.target.value,
              },
            });
          }}
        />
      </div>
      {settings.get(settingsParams.deliveryDate) && renderDatePicker()}
      <div className={s.textFieldContainer}>
        <TextArea
          data-hook="rise-gift-card-message-text-area"
          maxLength={shouldUseNewGiftCardServices ? MAX_MESSAGE_LENGTH : 1200}
          className={classes.textArea}
          label={settings.get(settingsParams.giftMessageLabel)}
          onChange={(e) => {
            dispatch({
              type: 'setPurchaseOptions',
              payload: {
                greetingMessage: e.target.value,
              },
            });
          }}
          ariaLabel={settings.get(settingsParams.giftMessageLabel)}
          value={checkout.purchaseOptions.greetingMessage}
        />
      </div>
    </>
  );
};

export default ProductC2cForm;
